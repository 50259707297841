import React from 'react';
import Home from './pages/Home';
import Translation from './pages/Translation';
import CertifiedLegalTranslation from './pages/CertifiedLegalTranslation';
import AboutUs from './pages/AboutUs';
import Pricing from './pages/Pricing';
import FAQ from './pages/FAQ';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import { Route, Link, Switch, Redirect } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Helmet from "react-helmet"
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation('home');
  return (
    <>
      <ScrollToTop />
      <Helmet htmlAttributes={{"lang": i18n.language}} />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/Translation" component={Translation} />
        <Route exact path="/CertifiedLegalTranslation" component={CertifiedLegalTranslation} />
        <Route exact path="/AboutUs" component={AboutUs} />
        <Route exact path="/Pricing" component={Pricing} />
        <Route exact path="/FAQ" component={FAQ} />
        <Route exact path="/Privacy" component={Privacy} />
        <Route exact path="/Terms" component={Terms} />
        <Route exact path='*'>
          <Redirect to="/" />
        </Route>
      </Switch>
    </>
  );
}

export default App;
