import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Disclosure } from '@headlessui/react'
import { PhoneIcon, MailIcon, ChevronDownIcon } from '@heroicons/react/outline'
import { useTranslation } from "react-i18next";
import CORUlogo_dark from '../assets/coru-logo-gray-500.svg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function FAQ() {
  const { t } = useTranslation();
  const faqs = [
    {
      question: t("faq_question_1"),
      answer: t("faq_answer_1"),
    },
    {
      question: t("faq_question_2"),
      answer: t("faq_answer_2"),
    },
    {
      question: t("faq_question_3"),
      answer: t("faq_answer_3"),
    },
    {
      question: t("faq_question_4"),
      answer: t("faq_answer_4"),
    },
    {
      question: t("faq_question_5"),
      answer: t("faq_answer_5"),
    },
    {
      question: t("faq_question_6"),
      answer: t("faq_answer_6"),
    },
    {
      question: t("faq_question_7"),
      answer: t("faq_answer_7"),
    },
  ]
  return (
    <div className="FAQ">
      <div className="bg-gradient-to-r from-green-500 to-teal-600">
      <Header 
        pageMeta={{
          title: "Frequently asked questions",
          keywords: ["translation", "irish", "gaelic", "gaeilge", "language", "official languages act", "snasta", "irish translation", "service", "english"],
          description: "Snasta are a leading provider of professional, Foras na Gaeilge accredited, Irish translation services to the public and private sectors.",
        }} t={t}
    />
      </div>

      <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">{t("faq_headline")}</h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
      <section className="mt-10 pt-8 pb-8 bg-white overflow-hidden md:pt-12 md:pb-16">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative">
            <img
              className="mx-auto h-16"
              src={CORUlogo_dark}
              alt="CORU"
            />
            <blockquote className="mt-9">
              <div className="max-w-3xl mx-auto text-center text-xl leading-9 font-light text-gray-900">
                <p>
                  &ldquo;{t("test_coru_head")}&rdquo;
              </p>
              </div>
              <footer className="mt-8">
                <div className="md:flex md:items-center md:justify-center">
                  <div className="md:flex-shrink-0">
                    {/*<img
                      className="mx-auto h-10 w-10 rounded-full"
                      src="https://media-exp1.licdn.com/dms/image/C4D03AQFDGm_ix3qHVg/profile-displayphoto-shrink_800_800/0/1517373055666?e=1625702400&v=beta&t=Kq1kFpCrgIKzV35-Zv_l9X-CTbxAIf4cwnkZyHjBDFY"
                      alt=""
                    />*/}
                  </div>
                  <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div className="text-base font-medium text-gray-900">{t("test_coru_name")}</div>

                    <svg className="hidden md:block mx-1 h-5 w-5 text-gray-600" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>

                    <div className="text-base font-medium text-gray-500">{t("test_coru_post")}</div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>
    </div>
    <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">{t("cta_headline4")}</span>
          <span className="block text-green-600">{t("cta_headline2")}</span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <a
              href="tel:+353539407070"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
            ><PhoneIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            053 940 7070
            </a>
          </div>
          <div className="ml-3 inline-flex rounded-md shadow">
            <a
              href="mailto:info@snasta.ie"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-gray-100"
            ><MailIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              info@snasta.ie
            </a>
          </div>
        </div>
      </div>
    </div>
      <Footer t={t} />
    </div>
  );
}

export default FAQ;