/* This example requires Tailwind CSS v2.0+ */
import i18next from 'i18next';
import SnastaLogoGreen from '../assets/logo_green.svg';
import {Link} from 'react-router-dom';

  export default function Footer({ t, i18n }) {

    const navigation = {
      services: [
        { name: t("footer_col1_2"), href: '/Translation' },
        { name: t("footer_col1_3"), href: '/CertifiedLegalTranslation' },
      /*  { name: 'Voice Over', href: 'Voice-Over' }, */
        { name: t("footer_col1_4"), href: '/Pricing' },
      ],
      support: [
        { name: t("footer_col2_2"), href: '/AboutUs' },
        {/* name: 'Snasta Teoranta', href: 'AboutUs' */},
      ],
    more: [
        { name: t("footer_col3_2"), href: '/FAQ' },
     /*   { name: 'Memsource Resource', href: '#' },
        { name: 'Pay an Invoice', href: '#' },
        { name: 'Work with Us', href: '#' },
      */
      ],
  
      legal: [
        { name: t("footer_col4_2"), href: '/Terms' },
        { name: t("footer_col4_3"), href: '/Privacy' },
      ],
      social: [
        {
          name: 'Facebook',
          href: 'https://www.facebook.com/snasta.ie',
          icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
              <path
                fillRule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clipRule="evenodd"
              />
            </svg>
          ),
        },
      ],
    }
    return (
      <footer className="bg-white" aria-labelledby="footerHeading">
        <h2 id="footerHeading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8 xl:col-span-1">
              <img
                className="h-14"
                src={SnastaLogoGreen}
                alt="Snasta"
              />
              <p className="text-gray-500 text-base">
                {t("footer_subline")}
              </p>
              <div className="flex space-x-6">
                {navigation.social.map((item) => (
                  <a key={item.name} href={item.href} target="_blank" rel="noreferrer" className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-8 w-8" aria-hidden="true" />
                  </a>
                ))}
              </div>
            </div>
            <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">{t("footer_col1_1")}</h3>
                  <ul className="mt-4 space-y-4">
                    {navigation.services.map((item) => (
                      <li key={item.name}>
                        <Link to={item.href} className="text-base text-gray-500 hover:text-gray-900">
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">{t("footer_col2_1")}</h3>
                  <ul className="mt-4 space-y-4">
                    {navigation.support.map((item) => (
                      <li key={item.name}>
                        <Link to={item.href} className="text-base text-gray-500 hover:text-gray-900">
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">{t("footer_col3_1")}</h3>
                  <ul className="mt-4 space-y-4">
                    {navigation.more.map((item) => (
                      <li key={item.name}>
                        <Link to={item.href} className="text-base text-gray-500 hover:text-gray-900">
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">{t("footer_col4_1")}</h3>
                  <ul className="mt-4 space-y-4">
                    {navigation.legal.map((item) => (
                      <li key={item.name}>
                        <Link to={item.href} className="text-base text-gray-500 hover:text-gray-900">
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 border-t border-gray-200 pt-8">
            <p className="text-sm text-gray-400 xl:text-center">&copy; 2010-{(new Date().getFullYear())} {t("footer_copyr")}</p>
          </div>
        </div>
      </footer>
    )
  }
  