import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import {
  AcademicCapIcon,
  BookOpenIcon,
  CheckIcon,
  CodeIcon,
  DeviceMobileIcon,
  DocumentIcon,
  DocumentReportIcon,
  GlobeAltIcon,
  GlobeIcon,
  HeartIcon,
  InboxIcon,
  InformationCircleIcon,
  LibraryIcon,
  MapIcon,
  PencilAltIcon,
  ReceiptRefundIcon,
  ReplyIcon,
  ScaleIcon,
  PhoneIcon,
  MailIcon,
  SpeakerphoneIcon,
  SupportIcon,
  TrashIcon,
  UsersIcon,
  CurrencyEuroIcon,
} from '@heroicons/react/outline'
import { useTranslation } from "react-i18next";

function Translation() {
  const { t } = useTranslation();
  const features = [
    {
      name: t("translation_feat_name1"),
      description: t("translation_feat_desc1"),
      icon: CodeIcon,
    },
    {
      name: t("translation_feat_name2"),
      description: t("translation_feat_desc2"),
      icon: DeviceMobileIcon,
    },
    {
      name: t("translation_feat_name3"),
      description: t("translation_feat_desc3"),
      icon: BookOpenIcon,
    },
    {
      name: t("translation_feat_name4"),
      description: t("translation_feat_desc4"),
      icon: MapIcon,
    },
    {
      name: t("translation_feat_name5"),
      description: t("translation_feat_desc5"),
      icon: DocumentReportIcon,
    },
    {
      name: t("translation_feat_name6"),
      description: t("translation_feat_desc6"),
      icon: LibraryIcon,
    },
    {
      name: t("translation_feat_name7"),
      description: t("translation_feat_desc7"),
      icon: ScaleIcon,
    },
    {
      name: t("translation_feat_name8"),
      description: t("translation_feat_desc8"),
      icon: SpeakerphoneIcon,
    },
    {
      name: t("translation_feat_name9"),
      description: t("translation_feat_desc9"),
      icon: SupportIcon,
    },
    {
      name: t("translation_feat_name10"),
      description: t("translation_feat_desc10"),
      icon: InformationCircleIcon,
    },
    {
      name: t("translation_feat_name11"),
      description: t("translation_feat_desc11"),
      icon: DocumentIcon,
    },
    {
      name: t("translation_feat_name12"),
      description: t("translation_feat_desc12"),
      icon: AcademicCapIcon,
    },
  ]
  return (
    <div className="Translation">
      <div className="bg-gradient-to-r from-green-500 to-teal-600">
        <Header
          pageMeta={{
            title: "Translation Services",
            keywords: ["translation", "irish", "gaelic", "gaeilge", "language", "official languages act", "snasta", "irish translation", "service", "english"],
            description: "Snasta are a leading provider of professional, Foras na Gaeilge accredited, Irish translation services to the public and private sectors.",
          }} t={t}
        />

        <div className="bg-white">
          <div className="relative bg-gradient-to-r from-green-500 to-teal-600">
            {/* Overlapping background */}
            <div aria-hidden="true" className="hidden absolute bg-white w-full h-6 bottom-0 lg:block" />

            <div className="relative max-w-2xl mx-auto pt-8 px-4 text-center sm:px-6 lg:max-w-7xl lg:px-8">
              <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-6xl">
                <span className="block">{t("translation_headline")}</span>
              </h1>
              <p className="mt-4 text-xl text-green-100">
              {t("translation_subline")}
          </p>
            </div>

            {/* Cards */}
            <div className="relative mt-8 max-w-2xl mx-auto px-4 pb-8 sm:mt-12 sm:px-6 lg:max-w-7xl lg:px-8 lg:pb-0">
              {/* Decorative background */}
              <div
                aria-hidden="true"
                className="hidden absolute top-4 bottom-6 left-8 right-8 inset-0 bg-green-700 rounded-tl-lg rounded-tr-lg lg:block"
              />

              <div className="relative space-y-6 lg:space-y-0 lg:grid lg:grid-cols-1">

                <div className="bg-white ring-2 ring-green-700 shadow-md pt-6 px-6 pb-3 rounded-lg grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16 lg:px-8 lg:px-12 lg:py-12">
                  {features.map((feature) => (
                    <div key={feature.name}>
                      <div>
                        <span className="flex items-center justify-center h-12 w-12 rounded-full bg-green-700">
                          <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <div className="mt-6">
                        <h3 className="text-lg font-medium text-gray-700">{feature.name}</h3>
                        <p className="mt-2 text-base text-gray-500">{feature.description}</p>
                      </div>
                    </div>
                  ))}
                  <div className="col-span-1 sm:col-span-2 lg:col-span-4">
                    <div className="w-full grid justify-items-end">
                      <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                        <div className="inline-flex rounded-md shadow">
                          <Link
                            to="/Pricing"
                            id="12312"
                            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700">
                            <CurrencyEuroIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />{t("header_pricing")}
                          </Link>
                        </div>
                        <div className="ml-3 inline-flex rounded-md shadow">
                          <a
                            href="mailto:info@snasta.ie"
                            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-gray-100"
                          ><MailIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                             info@snasta.ie
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 lg:py-20">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">{t("translation_stats_headline")}</h2>
            <p className="mt-3 text-xl text-gray-400 sm:mt-4">
            {t("translation_stats_subline")}
          </p>
          </div>
          <dl className="mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8">
            <div className="flex flex-col">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-400">{t("translation_stats_left_bottom")}</dt>
              <dd className="order-1 text-5xl font-extrabold text-gray-900">{t("translation_stats_left_mid")}</dd>
              <dt className="order-0 mb-2 text-lg leading-6 font-medium text-gray-400">{t("translation_stats_left_top")}&nbsp;</dt>
            </div>
            <div className="flex flex-col mt-10 sm:mt-0">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-400">{t("translation_stats_mid_bottom")}</dt>
              <dd className="order-1 text-5xl font-extrabold text-gray-900">{t("translation_stats_mid_mid")}</dd>
              <dt className="order-0 mb-2 text-lg leading-6 font-medium text-gray-400">{t("translation_stats_mid_top")}&nbsp;</dt>
            </div>
            <div className="flex flex-col mt-10 sm:mt-0">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-400">{t("translation_stats_right_bottom")}</dt>
              <dd className="order-1 text-5xl font-extrabold text-gray-900">{t("translation_stats_right_mid")}</dd>
              <dt className="order-0 mt-2 text-lg leading-6 font-medium text-gray-400">{t("translation_stats_right_top")}&nbsp;</dt>
            </div>
          </dl>
        </div>
      </div>


      <div className="bg-gray-100">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">{t("cta_headline1")}</span>
            <span className="block text-green-600">{t("cta_headline2")}</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <a
                href="tel:+353539407070"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              ><PhoneIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            053 940 70 70
            </a>
            </div>
            <div className="ml-3 inline-flex rounded-md shadow">
              <a
                href="mailto:info@snasta.ie"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-gray-100"
              ><MailIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              Email Us
            </a>
            </div>
          </div>
        </div>
      </div>


      <Footer t={t} />
    </div>
  );
}

export default Translation;