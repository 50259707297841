import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { PhoneIcon, MailIcon } from '@heroicons/react/outline'
import { useTranslation } from "react-i18next";

function Privacy() {
    const { t } = useTranslation();
  return (
    <div className="Privacy">
      <div className="bg-gradient-to-r from-green-500 to-teal-600">
      <Header 
        pageMeta={{
          title: "Privacy Notice",
          keywords: ["translation", "irish", "gaelic", "gaeilge", "language", "official languages act", "snasta", "irish translation", "service", "english"],
          description: "Snasta are a leading provider of professional, Foras na Gaeilge accredited, Irish translation services to the public and private sectors.",
        }} t={t}
    />
      </div>

    <div className="relative py-16 bg-white overflow-hidden">
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-green-600 font-semibold tracking-wide uppercase">
              Snasta Teoranta
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Privacy Notice
            </span>
          </h1>
          <p className="mt-4 text-xl text-gray-500 leading-8">
          This is the privacy notice of Snasta Teoranta, trading as <strong>Snasta</strong>. Registered in Dublin, Ireland. Registered number 685922. (‘we’, ‘our’, or ‘us’).
          </p>
        </div>
        <div className="text-lg max-w-prose mx-auto text-gray-500">
          <p className="mt-4 text-xl text-gray-500 leading-8">
          Our registered office is at <strong>37 Cnoc na Darach, Guaire, Co. Loch Garman, Y25 K6P4, Ireland.</strong>
          </p>
          <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
Introduction</h3>

          <p className="mt-4 text-xl text-gray-500 leading-8">
          This notice describes how we collect, store, transfer and use personal data. It tells you about your privacy rights and how the law protects you.
          </p>
          <p className="mt-4 text-xl text-gray-500 leading-8">
          In the context of the law and this notice, ‘personal data’ is information that clearly identifies you as an individual or which could be used to identify you if combined with other information. Acting in any way on personal data is referred to as ‘processing’.
          </p>
          <p className="mt-4 text-xl text-gray-500 leading-8">
          This notice applies to personal data collected through our website and email, and in rare cases, through social media platforms and online retail platforms, including Facebook and LinkedIn.
          </p>
          <p className="mt-4 text-xl text-gray-500 leading-8">
          Except as set out below, we do not share, or sell, or disclose to a third party, any information collected.
          </p>

          <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
Data Protection Officer</h3>

          <p className="mt-4 text-xl text-gray-500 leading-8">
          We have appointed a data protection officer (‘DPO’) who is responsible for ensuring that our privacy policy is followed.
          </p>
          <p className="mt-4 text-xl text-gray-500 leading-8">
          If you have any questions about how we process your personal data, including any requests to exercise your legal rights, please contact our DPO, <strong>James Pelow</strong>, at <a href="mailto:privacy@snasta.ie">privacy@snasta.ie</a>.
          </p>

          <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
Personal data we process</h3>

          <p className="mt-4 text-xl text-gray-500 leading-8">
    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
1. How we obtain personal data</h3>

</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    The information we process about you includes information:
</p>
<ul className="list-disc text-xl ml-8">
    <li>
        you have directly provided to us
    </li>
    <li>
        that we gather from third party databases and service providers
    </li>
    <li>
        as a result of monitoring how you use our website or our services
    </li>
</ul>

<h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
2. Types of personal data we collect directly</h3>

<p className="mt-4 text-xl text-gray-500 leading-8">
    When you use our website or our services, we ask you to provide personal
    data. This can be categorised into the following groups:
</p>
<ul className="list-disc text-xl ml-8">
    <li>
        personal identifiers, such as your first and last names, your title and
        your date of birth
    </li>
    <li>
        contact information, such as your email address, your telephone number
        and your postal addresses for billing, delivery and communication
    </li>
    <li>
        account information, including your username and password
    </li>
    <li>
        payment information, such as a debit or credit card number and expiry
        date and bank account details
    </li>
    <li>
        records of communication between us including messages sent through our
        website, email messages and telephone conversations
    </li>
    <li>
        marketing preferences that tell us what types of marketing you would
        like to receive
    </li>
</ul>
<p className="mt-4 text-xl text-gray-500 leading-8">
    In addition, we may also process:
</p>
<ul className="list-disc text-xl ml-8">
    <li>
        documentation that confirms your identity, such as an image of your
        passport or driver&#8217;s licence
    </li>
    <li>
        an image that shows your face, such as a passport photograph
    </li>
    <li>
        documentation that confirms the qualifications you advertise as holding
    </li>
    <li>
        documentation that confirms your employment, such as recent payslips
    </li>
    <li>
        documentation that confirms your address, such as a tenancy agreement
        or rental contract
    </li>
</ul>

<h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
3. Types of personal data we collect from third parties</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    We confirm some of the information you provide to us directly using data
    from other sources. We also add to the information we hold about you,
    sometimes to remove the need for you to provide it to us and sometimes in
    order to be able to assess the quality of the services you offer.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    The additional information we collect can be categorised as follows:
</p>
<ul className="list-disc text-xl ml-8">
    <li>
        information that confirms your identity
    </li>
    <li>
        business information, including your business trading name and address,
        your company&#8217;s registered number (if incorporated), and your VAT
        number (if registered)
    </li>
    <li>
        information that confirms your contact information
    </li>
    <li>
        reviews and feedback about your business on other websites through
        which you sell your services
    </li>
    <li>
        unsolicited complaints by other users
    </li>
</ul>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
4. Types of personal data we collect from your use of our services</h3>

<p className="mt-4 text-xl text-gray-500 leading-8">
    By using our website and our services, we may process:
</p>
<ul className="list-disc text-xl ml-8">
    <li>
        technical information about the hardware and the software you use to
        access our website and use our services, including your Internet
        Protocol (IP) address, your browser type and version and your
        device&#8217;s operating system
    </li>
    <li>
        usage information, including the frequency you use our services, the
        pages of our website that you visit, whether you receive messages from
        us and whether you reply to those messages
    </li>
    <li>
        transaction information that includes the details of the products
        services you have bought from us and payments made to us for those
        services
    </li>
    <li>
        your preferences to receive marketing from us; how you wish to
        communicate with us; and responses and actions in relation to your use
        of our services.
    </li>
</ul>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
5. Our use of aggregated information</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    We may aggregate anonymous information such as statistical or demographic
    data for any purpose. Anonymous information is that which does not identify
    you as an individual. Aggregated information may be derived from your
    personal data but is not considered as such in law because it does not
    reveal your identity.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    For example, we may aggregate usage information to assess whether a feature
    of our website is useful.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    However, if we combine or connect aggregated information with your personal
    data so that it can identify you in any way, we treat the combined
    information as personal data, and it will be used in accordance with this
    privacy notice.
</p>
    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
6. Special personal data</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    Special personal data is data about your race or ethnicity, religious or
    philosophical beliefs, sex life, sexual orientation, political opinions,
    trade union membership, information about your health and genetic and
    biometric data.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    It could also includes information about criminal convictions and offences.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    We do not collect any special personal data about you.
</p>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
7. If you do not provide personal data we need</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    Where we need to collect personal data by law, or under the terms of a
    contract we have with you, and you fail to provide that data when
    requested, we may not be able to perform that contract.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    In that case, we may have to stop providing a service to you. If so, we
    will notify you of this at the time.
</p>
<h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">

    8. The bases on which we process information about you
</h3>

<p className="mt-4 text-xl text-gray-500 leading-8">
    The law requires us to determine under which of six defined bases we
    process different categories of your personal data, and to notify you of
    the basis for each category.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    If a basis on which we process your personal data is no longer relevant
    then we shall immediately stop processing your data.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    If the basis changes then if required by law we shall notify you of the
    change and of any new basis under which we have determined that we can
    continue to process your information.
</p>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
9. Information we process because we have a contractual obligation with
        you</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    When you create an account on our website, buy a product or service from
    us, or otherwise agree to our terms and conditions, a contract is formed
    between you and us.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    In order to carry out our obligations under that contract we must process
    the information you give us. Some of this information may be personal data.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    We may use it in order to:
</p>
<ul className="list-disc text-xl ml-8">
    <li>
        verify your identity for security purposes when you use our services
    </li>
    <li>
        sell products to you
    </li>
    <li>
        provide you with our services
    </li>
    <li>
        provide you with suggestions and advice on products, services and how
        to obtain the most from using our website
    </li>
</ul>
<p className="mt-4 text-xl text-gray-500 leading-8">
    We process this information on the basis there is a contract between us, or
    that you have requested we use the information before we enter into a legal
    contract.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    We shall continue to process this information until the contract between us
    ends or is terminated by either party under the terms of the contract.
</p>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
10. Information we process with your consent</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    Through certain actions when otherwise there is no contractual relationship
    between us, such as when you browse our website or ask us to provide you
    more information about our business, including job opportunities and our
    products and services, you provide your consent to us to process
    information that may be personal data.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    Wherever possible, we aim to obtain your explicit consent to process this
    information, for example, we ask you to agree to our use of non-essential
    cookies when you access our website.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    If you have given us explicit permission to do so, we may from time to time
    pass your name and contact information to selected associates whom we
    consider may provide services or products you would find useful.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    We continue to process your information on this basis until you withdraw
    your consent or it can be reasonably assumed that your consent no longer
    exists.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    You may withdraw your consent at any time by contacting the DPO. However,
    if you do so, you may not be able to use our website or our services
    further.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    We aim to obtain and keep your consent to process your information.
    However, while we take your consent into account in decisions about whether
    or not to process your personal data, the withdrawal of your consent does
    not necessarily prevent us from continuing to process it. The law may allow
    us to continue to process your personal data, provided that there is
    another basis on which we may do so. For example, we may have a legal
    obligation to do so.
</p>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">11. Information we process for the purposes of legitimate interests
    </h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    We may process information on the basis there is a legitimate interest,
    either to you or to us, of doing so.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    Where we process your information on this basis, we do after having given
    careful consideration to:
</p>
<ul className="list-disc text-xl ml-8">
    <li>
        whether the same objective could be achieved through other means
    </li>
    <li>
        whether processing (or not processing) might cause you harm
    </li>
    <li>
        whether you would expect us to process your data, and whether you
        would, in the round, consider it reasonable to do so
    </li>
</ul>
<p className="mt-4 text-xl text-gray-500 leading-8">
    For example, we may process your data on this basis for the purposes of:
</p>
<ul className="list-disc text-xl ml-8">
    <li>
        improving our services
    </li>
    <li>
        record-keeping for the proper and necessary administration of our
        company
    </li>
    <li>
        responding to unsolicited communication from you to which we believe
        you would expect a response
    </li>
    <li>
        preventing fraudulent use of our services
    </li>
    <li>
        exercising our legal rights, including to detect and prevent fraud and
        to protect our intellectual property
    </li>
    <li>
        insuring against or obtaining professional advice that is required to
        manage company risk
    </li>
    <li>
        protecting your interests where we believe we have a duty to do so
    </li>
</ul>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
12. Information we process because we have a legal obligation</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    Sometimes, we must process your information in order to comply with a
    statutory obligation.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    For example, we may be required to give information to legal authorities if
    they so request or if they have the proper authorisation such as a search
    warrant or court order.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    This may include your personal data.
</p>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
13. Information we process to protect vital interests</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    In situations where processing personal information is necessary to protect
    someone's life, where consent is unable to be given and where other
    lawful bases are not appropriate, we may process personal information on
    the basis of vital interests.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    For example, we may inform relevant organisations if we have a safeguarding
    concern about a vulnerable person.
</p>
<h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">

    How and when we process your personal data
</h3>


    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
14. Your personal data is not shared</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    We do not share or disclose to a third party any information collected
    through our website.
</p>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
15. Payment information</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    Payment information is never taken by us or transferred to us either
    through our website or otherwise. Our employees and contractors never have
    access to it.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    At the point of payment, you are transferred to a secure page on the
    website of Stripe Payments Europe Limited. That page may be branded to look like a page on our
    website, but it is not controlled by us.
</p>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
16. Direct Debit information</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    When you agree to set up a Direct Debit arrangement, the information you
    give to us is passed to either Stripe Payments Europe Limited or Revolut Bank UAB Irish Branch, for processing
    according to our instructions. We do not keep a copy.
</p>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
17. Job application and employment</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    If you send us information in connection with a job application, it will be
    kept in line with our normal email retention policy in case we decide to
    contact you at a later date.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    If we engage you, we collect information about you and your work from time
    to time throughout the period of your engagement. This information will be
    used only for purposes directly relevant to your engagement. After your
    engagement has ended, we will keep your file for six years before
    destroying or deleting it.
</p>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
18. Information obtained from third parties</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    Although we do not disclose your personal data to any third party (except
    as set out in this notice), we sometimes receive data that is indirectly
    made up from your personal data from third parties whose services we use.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    No such information is personally identifiable to you.
</p>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
19. Service providers and business partners</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    We may share your personal data with businesses that provide services to
    us, or with business partners.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    As examples:
</p>
<ul className="list-disc text-xl ml-8">
    <li>
        we may pass your payment information to our payment service provider to
        take payments from you
    </li>
    <li>
        we may use fraud prevention agencies and credit reference agencies to
        verify your identity and we may pass your information to those agencies
        if we strongly suspect fraud on our website
    </li>
    <li>
        we may pass your contact information to advertising agencies to use to
        promote our services to you
    </li>
</ul>
<h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">

    Use of information we collect through automated systems
</h3>


    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
20. Cookies</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    We do not use cookies.
</p>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
21. Personal identifiers from your browsing activity</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    Requests by your web browser to our servers for web pages and other content
    on our website are recorded.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    We record information such as your geographical location, your Internet
    service provider and your IP address. We also record information about the
    software you are using to browse our website, such as the type of computer
    or device and the screen resolution.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    We use this information in aggregate to assess the popularity of the
    webpages on our website and how we perform in providing content to you.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    If combined with other information we know about you from previous visits,
    the data possibly could be used to identify you personally, even if you are
    not signed in to our website.
</p>
<h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">

    Other matters
</h3>


    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
22. Your rights</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    The law requires us to tell you about your rights and our obligations to
    you in regard to the processing and control of your personal data.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    We do this now, by requesting that you read the information provided by the Data Protection Commission at <a href="https://www.dataprotection.ie/en/individuals/rights-individuals-under-general-data-protection-regulation" target="_blank">https://www.dataprotection.ie/en/individuals/rights-individuals-under-general-data-protection-regulation</a>
</p>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
23. Use of our services by children</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    We do not sell products or provide services for purchase by children, nor
    do we market to children.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    If you are under 18, you may use our website only with consent from a
    parent or guardian.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    We collect data about all users of and visitors to these areas regardless
    of age, and we anticipate that some of those users and visitors will be
    children.
</p>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
24. Encryption of data sent between us</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    We use Transport Layer Security (TLS) to verify our identity to your
    browser and to encrypt any data you give us.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    Whenever information is transferred between us, you can check that it is
    done so using TLS by looking for a closed padlock symbol or other trust
    mark in your browser&#8217;s URL bar or toolbar.
</p>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
25. 
        Delivery of services using third party communication software
    </h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    With your consent, we may communicate using software provided by a third
    party such as Facebook (WhatsApp), Apple (FaceTime), or Zoom Video
    Communications (Zoom).
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    Such methods of communication should secure your personal data using
    encryption and other technologies. The providers of such software should
    comply with all applicable privacy laws, rules, and regulations, including
    the GDPR.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    If you have any concerns about using a particular software for
    communication, please tell us.
</p>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
26. Data may be processed outside the European Union</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    Our websites are hosted in Ireland.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    We may also use outsourced services in countries outside the European Union
    from time to time in other aspects of our business.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    Accordingly data obtained within Ireland or any other country could be
    processed outside the European Union.
</p>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
27. Control over your own information</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    It is important that the personal data we hold about you is accurate and up
    to date. Please inform us if your personal data changes.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    At any time, you may contact us to request that we provide you with the
    personal data we hold about you.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    When we receive any request to access, edit or delete personal data we
    first take reasonable steps to verify your identity before granting you
    access or otherwise taking any action. This is important to safeguard your
    information.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    Please be aware that we are not obliged by law to provide you with all
    personal data we hold about you, and that if we do provide you with
    information, the law allows us to charge for such provision if doing so
    incurs costs for us. After receiving your request, we will tell you when we
    expect to provide you with the information, and whether we require any fee
    for providing it to you.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    If you wish us to remove personally identifiable information from our
    website, you should contact us to make your request.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    This may limit the service we can provide to you.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    We remind you that we are not obliged by law to delete your personal data
    or to stop processing it simply because you do not consent to us doing so.
    While having your consent is an important consideration as to whether to
    process it, if there is another legitimate basis on which we may process
    it, we may do so on that basis.
</p>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
28. Communicating with us</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    When you contact us, whether by telephone, through our website or by email,
    we collect the data you have given to us in order to reply with the
    information you need.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    We may keep personally identifiable information associated with your
    message, such as your name and email address so as to be able to track our
    communications with you to provide a high quality service.
</p>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
29. Complaining</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    If you are not happy with our privacy policy, or if you have any complaint,
    then you should tell us.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    When we receive a complaint, we record the information you have given to us
    on the basis of consent. We use that information to resolve your complaint.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    If your complaint reasonably requires us to notify some other person, we
    may decide to give to that other person some of the information contained
    in your complaint. We do this as infrequently as possible, but it is a
    matter for our sole discretion whether we do give information, and if we
    do, what that information is.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    We may also compile statistics showing information obtained from this
    source to assess the level of service we provide, but not in a way that
    could identify you or any other person.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    If a dispute is not settled then we hope you will agree to attempt to
    resolve it by engaging in good faith with us in a process of mediation or
    arbitration.
</p>
<p className="mt-4 text-xl text-gray-500 leading-8">
    If you are in any way dissatisfied about how we process your personal data,
    you have a right to lodge a complaint with the Data Protection Commission
    (DPC). This can be done at https://www.dataprotection.ie. We would,
    however, appreciate the opportunity to talk to you about your concern
    before you approach the DPC.
</p>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
30. Retention period</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    Except as otherwise mentioned in this privacy notice, we keep your personal
    data only for as long as required by us:
</p>
<ul className="list-disc text-xl ml-8">
    <li>
        to provide you with the services you have requested
    </li>
    <li>
        to comply with other law, including for the period demanded by our tax
        authorities
    </li>
    <li>
        to support a claim or defence in court
    </li>
</ul>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
31. Compliance with the law</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    Our privacy policy complies with the law in Ireland, specifically with the
    Data Protection Act 2018 (the &#8216;Act&#8217;) accordingly incorporating
    the EU General Data Protection Regulation (&#8216;GDPR&#8217;) and the
    European Communities (Electronic Communications Networks and Services)
    (Privacy and Electronic Communications) Regulations 2011.
</p>

    <h3 className="mt-8 mb-0 p-0 text-2xl font-bold text-gray-900 leading-8">
32. Review of this privacy policy</h3>


<p className="mt-4 text-xl text-gray-500 leading-8">
    We shall update this privacy notice from time to time as necessary.
</p>
      </div>
    </div>
    </div>
    <div className="bg-gradient-to-r from-green-500 to-teal-600">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block text-gray-900">Privacy questions or concerns?</span>
            <span className="block text-green-100">Contact the Data Protection Officer.</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <a
                href="tel:+35315549651"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              ><PhoneIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            01 554 9651
            </a>
            </div>
            <div className="ml-3 inline-flex rounded-md shadow">
              <a
                href="mailto:privacy@snasta.ie"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-gray-100"
              ><MailIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              privacy@snasta.ie
            </a>
            </div>
          </div>
        </div>
      </div>
      <Footer t={t} />
    </div>
  );
}

export default Privacy;