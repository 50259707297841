import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { CheckCircleIcon } from '@heroicons/react/solid'
import { ClipboardCheckIcon, ClipboardCopyIcon, DuplicateIcon, EyeIcon, MailIcon, ShieldCheckIcon, TranslateIcon } from '@heroicons/react/outline'
import { CheckIcon, PhoneIcon } from '@heroicons/react/outline'
import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon, ExclamationIcon } from '@heroicons/react/outline'
import { useTranslation } from "react-i18next";

function CertifiedLegalTranslation() {
  const { t } = useTranslation();
  const includedFeatures = [
    t("certified_feat_1"),
    t("certified_feat_2"),
    t("certified_feat_3"),
    t("certified_feat_4"),
  ]
  
  const Features = [
    {
      id: 1,
      name: t("certified_step_name_1"),
      description: t("certified_step_desc_1"),
      icon: DuplicateIcon,
    },
    {
      id: 2,
      name: t("certified_step_name_2"),
      description: t("certified_step_desc_2"),
      icon: EyeIcon,
    },
    {
      id: 3,
      name: t("certified_step_name_3"),
      description: t("certified_step_desc_3"),
      icon: MailIcon,
    },
    {
      id: 4,
    name: t("certified_step_name_4"),
    description: t("certified_step_desc_4"),
    icon: TranslateIcon,
  },
  {
  
  id: 5,
  name: t("certified_step_name_5"),
  description: t("certified_step_desc_5"),
  icon: ShieldCheckIcon,
  
  },
  {
    id: 6,
  name: t("certified_step_name_6"),
  description: t("certified_step_desc_6"),
  icon: CheckIcon,
  },
  ]
  return (
    <div className="CertifiedLegalTranslation">
      <div className="bg-gradient-to-r from-green-500 to-teal-600">
      <Header 
        pageMeta={{
          title: "ATII Certified Legal Translation",
          keywords: ["translation", "irish", "gaelic", "gaeilge", "language", "official languages act", "snasta", "irish translation", "service", "english"],
          description: "Snasta are a leading provider of professional, Foras na Gaeilge accredited, Irish translation services to the public and private sectors.",
        }} t={t}
    />
      </div>
     
      <div className="relative bg-gradient-to-r from-green-500 to-teal-600">
        <div className="pt-8">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-6xl">
                <span className="block">{t("certified_headline")}</span>
              </h1>
              <p className="mt-4 text-xl text-green-100">
              {t("certified_subline")}
          </p>
            </div>
          </div>
        </div>
        <div className="mt-8 bg-white pb-0 sm:mt-12">
          <div className="relative">
            <div className="absolute inset-0 h-1/2 bg-gradient-to-r from-green-500 to-teal-600" />
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
                <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                  <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">{t("certified_box_headline")}</h3>
                  <p className="mt-6 text-base text-gray-500">
                   {t("certified_box_subline")}
                </p>
                  <div className="mt-8">
                    <div className="flex items-center">
                      <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-green-600">
                      {t("certified_box_subline_green")}
                    </h4>
                      <div className="flex-1 border-t-2 border-gray-200" />
                    </div>
                    <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                      {includedFeatures.map((feature) => (
                        <li key={feature} className="flex items-start lg:col-span-1">
                          <div className="flex-shrink-0">
                            <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                          </div>
                          <p className="ml-3 text-sm text-gray-700">{feature}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                  <p className="text-lg leading-6 font-medium text-gray-900">{t("certified_flat_fee")}</p>
                  <div className="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                    <span>€50</span>
                    <span className="ml-3 text-xl font-medium text-gray-500">{t("certified_vat")}</span>
                  </div>
                  <p className="mt-4 text-sm">
                  {t("certified_inc")}
                  </p>
                  <div className="mt-6">
                    <div className="rounded-md shadow">
                      <a
                        href="mailto:info@snasta.ie?subject=Certified Legal Translation"
                        className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-gray-900"
                      ><MailIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                      {t("certified_cta")}
                    </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="py-16 bg-white overflow-hidden">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              {t("certified_step_headline")}
            </h3>
              <p className="mt-3 text-lg text-gray-500">
              {t("certified_step_subline")}
            </p>

              <dl className="mt-10 space-y-10">
                {Features.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-full bg-green-500 text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">

            </div>
          </div>



          
        </div>
      </div>

      <div className="bg-gray-100">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block text-green-600">{t("cta_headline6")}</span>
            <span className="block">{t("cta_headline7")}</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <a
                href="tel:+353539407070"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              ><PhoneIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            053 940 70 70
            </a>
            </div>
            <div className="ml-3 inline-flex rounded-md shadow">
              <a
                href="mailto:info@snasta.ie"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-gray-100"
              ><MailIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              info@snasta.ie
            </a>
            </div>
          </div>
        </div>
      </div>

      <Footer t={t} />
    </div>
  );
}

export default CertifiedLegalTranslation;