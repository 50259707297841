import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { PhoneIcon, MailIcon } from '@heroicons/react/outline'
import { useTranslation } from "react-i18next";

function Terms() {
  const { t } = useTranslation();
  return (
    <div className="Privacy">
      <div className="bg-gradient-to-r from-green-500 to-teal-600">
      <Header 
        pageMeta={{
          title: "Terms and Conditions",
          keywords: ["translation", "irish", "gaelic", "gaeilge", "language", "official languages act", "snasta", "irish translation", "service", "english"],
          description: "Snasta are a leading provider of professional, Foras na Gaeilge accredited, Irish translation services to the public and private sectors.",
        }} t={t}
    />
      </div>

    <div className="relative py-16 bg-white overflow-hidden">
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-green-600 font-semibold tracking-wide uppercase">
              Snasta Teoranta
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Terms and Conditions
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
          These are the terms and conditions under which Snasta Teoranta provides translation services. Snasta Teoranta, trading as Snasta, is registered in Dublin, Ireland. Registered number 685922. (‘Snasta Teoranta’, ‘Snasta’, ‘we’, ‘our’, or ‘us’).
          </p>
          <p className="mt-8 text-xl text-gray-500 leading-8">
          Our registered office is at <strong>37 Cnoc na Darach, Guaire, Co. Loch Garman, Y25 K6P4, Ireland.</strong>
          </p>

 <p className="mt-8 text-xl text-gray-500 leading-8">Snasta shall make every effort to complete service(s) by the quoted completion date but shall not be responsible for delays in completion caused by events beyond Snasta’s control.</p>

 <p className="mt-8 text-xl text-gray-500 leading-8">The due date for payment of fees and costs under this Agreement shall be 30 days from the invoice date, unless otherwise agreed. European Communities (Late Payment in Commercial Transactions) Regulations 2012 - SI 580 of 2012 shall apply to all late payments and Snasta shall be automatically entitled, without the necessity of a reminder, to statutory interest and compensation for late payments.</p>

 <p className="mt-8 text-xl text-gray-500 leading-8">If, after confirming their order, the Client cancels or withdraws any portion of the agreed item(s) prior to Snasta’s completion of the service(s), then, in consideration of Snasta’s scheduling and/or performance of said service(s), Client shall pay Snasta the portion of the above fee represented by the percentage of total service(s) performed, but in any event not less than 20% of said fee.</p>

 <p className="mt-8 text-xl text-gray-500 leading-8">Additional fees may become payable where the following additional services are required: (a) investigation, inquiry, or research beyond that normal to a routine translation because of ambiguities in the item(s) to be translated; (b) additional services because Client makes changes in the item(s) to be translated after the commencement of this Agreement; and (c) Translator is requested to make changes to the translation after delivery of the translation, because of Client’s preferences as to style or vocabulary, and such changes are not required for accuracy. Where such additional fees would become payable, they will in all cases be notified to the Client before they are incurred.</p>

 <p className="mt-8 text-xl text-gray-500 leading-8">Client shall reimburse Snasta for necessary out-of-pocket expenses incurred by Snasta that are not a normal part of routine translation procedure, such as next day document delivery service requested by Client. Where such additional costs would become payable, they will in all cases be notified to the Client before they are incurred.</p>

 <p className="mt-8 text-xl text-gray-500 leading-8">Due to the nature of translation it is impossible to guarantee that any work is 100% error free. Our optional proofreading and editing services reduce the risk of errors, but cannot totally exclude that risk. Upon receipt of the translation from Snasta, Client shall promptly review it, and within 15 days of receipt, shall notify Snasta of any requested corrections or changes. Snasta shall correct, at no cost to Client, any errors identified.</p>

 <p className="mt-8 text-xl text-gray-500 leading-8">All knowledge and information expressly identified by Client in writing as confidential which Snasta acquires during the term of this Agreement regarding the business and products of Client shall be maintained in confidentiality by Snasta and, except as expressly authorised by Client in writing, shall not be divulged or published by Snasta and shall not be authorised by Translator to be divulged or published by others. Confidential information for purposes of this paragraph shall not include the following:
<ol className="list-decimal ml-8 mt-2"><li>Information which is or becomes available to the general public, provided the disclosure of such information did not result from a breach by Snasta of this paragraph.</li>
<li>Terminological glossary entries or translation memories compiled by Snasta in the course of Snasta’s performance of the translation service(s) under this Agreement; provided, however, that Client and Snasta may agree in writing that, upon payment by Client to Snasta of an agreed-upon fee, such terminological glossary entries or translation memories shall be the property of Client and shall be covered by the confidentiality provisions of this paragraph.</li></ol></p>

 <p className="mt-8 text-xl text-gray-500 leading-8">Snasta may from time to time use Client's name and logo for the purpose of illustrating our broad client base and promoting our services. Client may opt out of this at any time.</p>

 <p className="mt-8 text-xl text-gray-500 leading-8">Upon Client’s completion of all payments provided herein, the translation of the item(s) described in paragraph 1 above shall be the property of Client. Snasta has no obligation to take any steps to protect any copyright, trademark or other right of Client with respect to the translation, except as may be expressly otherwise provided in this Agreement. Notwithstanding the foregoing, Snasta shall have the right to retain file copies of the item(s) to be translated and of the translation, subject to the provisions of paragraph 7 above.</p>

 <p className="mt-8 text-xl text-gray-500 leading-8">Client agrees to indemnify and hold Snasta (and all present and former employees and subcontractors) harmless from any and all losses, claims, damages, expenses or liabilities
(including reasonable solicitors’ fees) which Snasta may incur in performance or as a result of the work specified under this Agreement.</p>

 <p className="mt-8 text-xl text-gray-500 leading-8">Our liability (and that of our present and former employees and subcontractors) to you arising out of, or in connection with, this Agreement (whether for breach of contract, negligence, or otherwise) will be limited to €100. Nothing in this Agreement shall limit our liability to you (a) for fraud or fraudulent concealment or (b) to the extent that under any applicable law liability may not be limited.</p>

 <p className="mt-8 text-xl text-gray-500 leading-8">Snasta shall have no responsibility whatsoever for any changes made to the translation by persons other than Snasta.</p>

 <p className="mt-8 text-xl text-gray-500 leading-8">This Agreement shall be governed by the laws of the Republic of Ireland and the European Union.</p>

 <p className="mt-8 text-xl text-gray-500 leading-8">This is the complete agreement of the parties as to the subject matter hereof. Any changes in this Agreement must be agreed in writing by both parties. This Agreement becomes a binding contract when Client confirms the order, whether via email or otherwise.</p>
          
      </div>
    </div>
    </div>
    <div className="bg-gradient-to-r from-green-500 to-teal-600">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block text-gray-900">Questions or concerns about this agreement?</span>
            <span className="block text-green-100">Drop us a mail or give us a bell.</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <a
                href="tel:+353539407070"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              ><PhoneIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            053 940 70 70
            </a>
            </div>
            <div className="ml-3 inline-flex rounded-md shadow">
              <a
                href="mailto:privacy@snasta.ie"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-gray-100"
              ><MailIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              privacy@snasta.ie
            </a>
            </div>
          </div>
        </div>
      </div>
      <Footer t={t} />
    </div>
  );
}

export default Terms;