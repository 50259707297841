import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { CheckIcon, XIcon, PhoneIcon, MailIcon } from '@heroicons/react/outline'
import { useTranslation } from "react-i18next";
import CHESTlogo_dark from '../assets/chest-logo-gray-500.svg';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Pricing() {
  const { t } = useTranslation();
  const index = []
const plans = [
  {
    title: t("pricing_silver"),
    featured: false,
    description: t("pricing_silver_desc"),
    priceWord: '0.16',
    priceVAT: 23,
    mainFeatures: [
      { id: 1, value: t("pricing_silver_feat1") },
      { id: 2, value: t("pricing_silver_feat2") },
      { id: 3, value: t("pricing_silver_feat3") },
    ],
  },
  {
    title: t("pricing_gold"),
    featured: true,
    description: t("pricing_gold_desc"),
    priceWord: '0.20',
    priceVAT: 23,
    mainFeatures: [
      { id: 1, value: t("pricing_gold_feat1") },
      { id: 2, value: t("pricing_gold_feat2") },
      { id: 3, value: t("pricing_gold_feat3") },
      { id: 4, value: t("pricing_gold_feat4") },
    ],
  },
  {
    title: t("pricing_plat"),
    featured: false,
    description: t("pricing_plat_desc"),
    priceWord: '0.24',
    priceVAT: 23,
    mainFeatures: [
      { id: 1, value: t("pricing_plat_feat1") },
      { id: 2, value: t("pricing_plat_feat2") },
      { id: 3, value: t("pricing_plat_feat3") },
      { id: 4, value: t("pricing_plat_feat4") },
    ],
  },
]
const features = [
  {
    title: t("pricing_feats_item1"),
    tiers: [
      { title: 'starter', value: true },
      { title: 'popular', featured: true, value: true },
      { title: 'intermediate', value: true },
    ],
  },
  {
    title: t("pricing_feats_item2"),
    tiers: [
      { title: 'starter', value: true },
      { title: 'popular', featured: true, value: true },
      { title: 'intermediate', value: true },
    ],
  },
  {
    title: t("pricing_feats_item3"),
    tiers: [
      { title: 'starter', value: false },
      { title: 'popular', featured: true, value: false },
      { title: 'intermediate', value: true },
    ],
  },
  {
    title: t("pricing_feats_item4"),
    tiers: [
      { title: 'starter', value: false },
      { title: 'popular', featured: true, value: true },
      { title: 'intermediate', value: true },
    ],
  },
  {
    title: t("pricing_feats_item5"),
    tiers: [
      { title: 'starter', value: false },
      { title: 'popular', featured: true, value: false },
      { title: 'intermediate', value: true },
    ],
  },
  {
    title: t("pricing_feats_item6"),
    tiers: [
      { title: 'starter', value: false },
      { title: 'popular', featured: true, value: true },
      { title: 'intermediate', value: true },
    ],
  },
]
const technical = [
  {
    title: t("pricing_tech_item1"),
    tiers: [
      { title: 'starter', value: true },
      { title: 'popular', featured: true, value: true },
      { title: 'intermediate', value: true },
    ],
  },
  {
    title: t("pricing_tech_item2"),
    tiers: [
      { title: 'starter', value: false },
      { title: 'popular', featured: true, value: true },
      { title: 'intermediate', value: true },
    ],
  },
  {
    title: t("pricing_tech_item3"),
    tiers: [
      { title: 'starter', value: false },
      { title: 'popular', featured: true, value: false },
      { title: 'intermediate', value: true },
    ],
  },
  {
    title: t("pricing_tech_item4"),
    tiers: [
      { title: 'starter', value: false },
      { title: 'popular', featured: true, value: true },
      { title: 'intermediate', value: true },
    ],
  },
]

const pricing = [
  {
    title: t("pricing_price_item1"),
    tiers: [
      { title: 'starter', value: true },
      { title: 'popular', featured: true, value: true },
      { title: 'intermediate', value: true },
    ],
  },
  {
    title: t("pricing_price_item2"),
    tiers: [
      { title: 'starter', value: false },
      { title: 'popular', featured: true, value: '25%' },
      { title: 'intermediate', value: '25%' },
    ],
  },
  {
    title: t("pricing_price_item3"),
    tiers: [
      { title: 'starter', value: false },
      { title: 'popular', featured: true, value: '25%' },
      { title: 'intermediate', value: '25%' },
    ],
  },
  {
    title: t("pricing_price_item4"),
    tiers: [
      { title: 'starter', value: false },
      { title: 'popular', featured: true, value: '€30' },
      { title: 'intermediate', value: false },
    ],
  },
  {
    title: t("pricing_price_item5"),
    tiers: [
      { title: 'starter', value: '50%' },
      { title: 'popular', featured: true, value: false },
      { title: 'intermediate', value: false },
    ],
  },
]
  return (
  
<div className="Home">
            <div className="bg-gradient-to-r from-green-500 to-teal-600">
            <Header 
        pageMeta={{
          title: "Pricing",
          keywords: ["translation", "irish", "gaelic", "gaeilge", "language", "official languages act", "snasta", "irish translation", "service", "english"],
          description: "Snasta are a leading provider of professional, Foras na Gaeilge accredited, Irish translation services to the public and private sectors.",
        }} t={t}
    />

        <div className="bg-gray-100">
      <div className="relative bg-gradient-to-r from-green-500 to-teal-600">
        {/* Overlapping background */}
        <div aria-hidden="true" className="hidden absolute bg-gray-100 w-full h-6 bottom-0 lg:block" />

        <div className="relative max-w-2xl mx-auto pt-8 px-4 text-center sm:px-6 lg:max-w-7xl lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-6xl">
            <span className="block">{t("pricing_headline")}</span>
          </h1>
          <p className="mt-4 text-xl text-green-100">
            {t("pricing_subline")}
          </p>
        </div>

        <h2 className="sr-only">Plans</h2>

        {/* Cards */}
        <div className="relative mt-8 max-w-2xl mx-auto px-4 pb-8 sm:mt-12 sm:px-6 lg:max-w-7xl lg:px-8 lg:pb-0">
          {/* Decorative background */}
          <div
            aria-hidden="true"
            className="hidden absolute top-4 bottom-6 left-8 right-8 inset-0 bg-green-700 rounded-tl-lg rounded-tr-lg lg:block"
          />

          <div className="relative space-y-6 lg:space-y-0 lg:grid lg:grid-cols-3">
            {plans.map((plan) => (
              <div
                key={plan.title}
                className={classNames(
                  plan.featured ? 'bg-white ring-2 ring-green-700 shadow-md' : 'bg-green-700 lg:bg-transparent',
                  'pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12'
                )}
              >
                <div>
                  <h3
                    className={classNames(
                      plan.featured ? 'text-green-600' : 'text-white',
                      'text-sm font-semibold uppercase tracking-wide'
                    )}
                  >
                    {plan.title}
                  </h3>
                  <div className="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                    <div className="mt-3 flex items-center">
                      <p
                        className={classNames(
                          plan.featured ? 'text-green-600' : 'text-white',
                          'text-4xl font-extrabold tracking-tight'
                        )}
                      >
                        €{plan.priceWord}
                      </p>
                      <div className="ml-4">
                        <p className={classNames(plan.featured ? 'text-gray-700' : 'text-white', 'text-sm')}>
                          /{t("pricing_word")}
                        </p>
                        <p className={classNames(plan.featured ? 'text-gray-500' : 'text-green-200', 'text-sm')}>
                          {t("pricing_exvat")} @ {plan.priceVAT}%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <h4 className="sr-only">Features</h4>
                <ul
                  className={classNames(
                    plan.featured
                      ? 'border-gray-200 divide-gray-200'
                      : 'border-green-500 divide-green-500 divide-opacity-75',
                    'mt-7 border-t divide-y lg:border-t-0'
                  )}
                >
                  {plan.mainFeatures.map((mainFeature) => (
                    <li key={mainFeature.id} className="py-3 flex items-center">
                      <CheckIcon
                        className={classNames(
                          plan.featured ? 'text-green-500' : 'text-green-200',
                          'w-5 h-5 flex-shrink-0'
                        )}
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          plan.featured ? 'text-gray-600' : 'text-white',
                          'ml-3 text-sm font-medium'
                        )}
                      >
                        {mainFeature.value}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Price comparison (up to lg) */}
      <section aria-labelledby="mobileComparisonHeading" className="lg:hidden">
        <h2 id="mobileComparisonHeading" className="sr-only">
          Price comparison
        </h2>

        <div className="max-w-2xl mx-auto py-16 px-4 space-y-16 sm:px-6">
          {plans.map((plan, planIndex) => (
            <div key="plan.title" className="border-t border-gray-200">
              <div
                className={classNames(
                  plan.featured ? 'border-green-600' : 'border-transparent',
                  '-mt-px pt-6 border-t-2 sm:w-1/2'
                )}
              >
                <h3 className={classNames(plan.featured ? 'text-green-600' : 'text-gray-900', 'text-sm font-bold')}>
                  {plan.title}
                </h3>
                <p className="mt-2 text-sm text-gray-500">{plan.description}</p>
              </div>
              <h4 className="mt-10 text-sm font-bold text-gray-900">{t("pricing_feats_head")}</h4>

              <div className="mt-6 relative">
                {/* Fake card background */}
                <div aria-hidden="true" className="hidden absolute inset-0 pointer-events-none sm:block">
                  <div
                    className={classNames(
                      plan.featured ? 'shadow-md' : 'shadow',
                      'absolute right-0 w-1/2 h-full bg-white rounded-lg'
                    )}
                  />
                </div>

                <div
                  className={classNames(
                    plan.featured ? 'ring-2 ring-green-600 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow',
                    'relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none'
                  )}
                >
                  <dl className="divide-y divide-gray-200">
                    {features.map((feature) => (
                      <div
                        key={feature.title}
                        className="py-3 flex items-center justify-between sm:grid sm:grid-cols-2"
                      >
                        <dt className="pr-4 text-sm font-medium text-gray-600">{feature.title}</dt>
                        <dd className="flex items-center justify-end sm:px-4 sm:justify-center">
                          {typeof feature.tiers[planIndex].value === 'string' ? (
                            <span
                              className={classNames(
                                feature.tiers[planIndex].featured ? 'text-green-600' : 'text-gray-900',
                                'text-sm font-medium'
                              )}
                            >
                              {feature.tiers[planIndex].value}
                            </span>
                          ) : (
                            <>
                              {feature.tiers[planIndex].value === true ? (
                                <CheckIcon className="mx-auto h-5 w-5 text-green-600" aria-hidden="true" />
                              ) : (
                                <XIcon className="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                              )}

                              <span className="sr-only">{feature.tiers[planIndex].value === true ? 'Yes' : 'No'}</span>
                            </>
                          )}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>

                {/* Fake card border */}
                <div aria-hidden="true" className="hidden absolute inset-0 pointer-events-none sm:block">
                  <div
                    className={classNames(
                      plan.featured ? 'ring-2 ring-green-600' : 'ring-1 ring-black ring-opacity-5',
                      'absolute right-0 w-1/2 h-full rounded-lg'
                    )}
                  />
                </div>
              </div>

              <h4 className="mt-10 text-sm font-bold text-gray-900">{t("pricing_tech_head")}</h4>

              <div className="mt-6 relative">
                {/* Fake card background */}
                <div aria-hidden="true" className="hidden absolute inset-0 pointer-events-none sm:block">
                  <div
                    className={classNames(
                      plan.featured ? 'shadow-md' : 'shadow',
                      'absolute right-0 w-1/2 h-full bg-white rounded-lg'
                    )}
                  />
                </div>

                <div
                  className={classNames(
                    plan.featured ? 'ring-2 ring-green-600 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow',
                    'relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none'
                  )}
                >
                  <dl className="divide-y divide-gray-200">
                    {technical.map((perk) => (
                      <div key={perk.title} className="py-3 flex justify-between sm:grid sm:grid-cols-2">
                        <dt className="text-sm font-medium text-gray-600 sm:pr-4">{perk.title}</dt>
                        <dd className="text-center sm:px-4">
                          {perk.tiers[planIndex].value === true ? (
                            <CheckIcon className="mx-auto h-5 w-5 text-green-600" aria-hidden="true" />
                          ) : (
                            <XIcon className="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                          )}

                          <span className="sr-only">{perk.tiers[planIndex].value === true ? 'Yes' : 'No'}</span>
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>

                {/* Fake card border */}
                <div aria-hidden="true" className="hidden absolute inset-0 pointer-events-none sm:block">
                  <div
                    className={classNames(
                      plan.featured ? 'ring-2 ring-green-600' : 'ring-1 ring-black ring-opacity-5',
                      'absolute right-0 w-1/2 h-full rounded-lg'
                    )}
                  />
                </div>
              </div>

              <h4 className="mt-10 text-sm font-bold text-gray-900">{t("pricing_price_head")}</h4>

              <div className="mt-6 relative">
                {/* Fake card background */}
                <div aria-hidden="true" className="hidden absolute inset-0 pointer-events-none sm:block">
                  <div
                    className={classNames(
                      plan.featured ? 'shadow-md' : 'shadow',
                      'absolute right-0 w-1/2 h-full bg-white rounded-lg'
                    )}
                  />
                </div>

                <div
                  className={classNames(
                    plan.featured ? 'ring-2 ring-green-600 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow',
                    'relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none'
                  )}
                >
                  <dl className="divide-y divide-gray-200">
                    {pricing.map((perk) => (
                      <div key={perk.title} className="py-3 flex justify-between sm:grid sm:grid-cols-2">
                        <dt className="text-sm font-medium text-gray-600 sm:pr-4">{perk.title}</dt>
                        <dd className="text-center sm:px-4">
                        {typeof perk.tiers[planIndex].value === 'string' ? (
                            <span
                              className={classNames(
                                perk.tiers[planIndex].featured ? 'text-green-600' : 'text-gray-900',
                                'text-sm font-medium'
                              )}
                            >
                              {perk.tiers[planIndex].value}
                            </span>
                          ) : (
                            <>
                              {perk.tiers[planIndex].value === true ? (
                                <CheckIcon className="mx-auto h-5 w-5 text-green-600" aria-hidden="true" />
                              ) : (
                                <XIcon className="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                              )}

                              <span className="sr-only">{perk.tiers[planIndex].value === true ? 'Yes' : 'No'}</span>
                            </>
                          )}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>

                {/* Fake card border */}
                <div aria-hidden="true" className="hidden absolute inset-0 pointer-events-none sm:block">
                  <div
                    className={classNames(
                      plan.featured ? 'ring-2 ring-green-600' : 'ring-1 ring-black ring-opacity-5',
                      'absolute right-0 w-1/2 h-full rounded-lg'
                    )}
                  />
                </div>
              </div>

            </div>
          ))}
        </div>
      </section>

      {/* Feature comparison (lg+) */}
      <section aria-labelledby="comparisonHeading" className="hidden lg:block">
        <h2 id="comparisonHeading" className="sr-only">
          {t("pricing_feats_head")}
        </h2>

        <div className="max-w-7xl mx-auto py-24 px-8">
          <div className="w-full border-t border-gray-200 flex items-stretch">
            <div className="-mt-px w-1/4 py-6 pr-4 flex items-end">
              <h3 className="mt-auto text-sm font-bold text-gray-900">{t("pricing_feats_head")}</h3>
            </div>
            {plans.map((plan) => (
              <div
                key={plan.title}
                aria-hidden="true"
                className={classNames(index === plans.length - 1 ? '' : 'pr-4', '-mt-px pl-4 w-1/4')}
              >
                <div
                  className={classNames(plan.featured ? 'border-green-600' : 'border-transparent', 'py-6 border-t-2')}
                >
                  <p className={classNames(plan.featured ? 'text-green-600' : 'text-gray-900', 'text-sm font-bold')}>
                    {plan.title}
                  </p>
                  <p className="mt-2 text-sm text-gray-500">{plan.description}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="relative">
            {/* Fake card backgrounds */}
            <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
              <div className="w-1/4 pr-4" />
              <div className="w-1/4 px-4">
                <div className="w-full h-full bg-white rounded-lg shadow" />
              </div>
              <div className="w-1/4 px-4">
                <div className="w-full h-full bg-white rounded-lg shadow-md" />
              </div>
              <div className="w-1/4 pl-4">
                <div className="w-full h-full bg-white rounded-lg shadow" />
              </div>
            </div>

            <table className="relative w-full">
              <caption className="sr-only">Business feature comparison</caption>
              <thead>
                <tr className="text-left">
                  <th scope="col">
                    <span className="sr-only">Feature</span>
                  </th>
                  {plans.map((plan) => (
                    <th key={plan.title} scope="col">
                      <span className="sr-only">{plan.title} plan</span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100">
                {features.map((feature) => (
                  <tr key={feature.title}>
                    <th scope="row" className="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                      {feature.title}
                    </th>
                    {feature.tiers.map((tier) => (
                      <td
                        key={tier.title}
                        className={classNames(
                          index === feature.tiers.length - 1 ? 'pl-4' : 'px-4',
                          'relative w-1/4 py-0 text-center'
                        )}
                      >
                        <span className="relative w-full h-full py-3">
                          {typeof tier.value === 'string' ? (
                            <span
                              className={classNames(
                                tier.featured ? 'text-green-600' : 'text-gray-900',
                                'text-sm font-medium'
                              )}
                            >
                              {tier.value}
                            </span>
                          ) : (
                            <>
                              {tier.value === true ? (
                                <CheckIcon className="mx-auto h-5 w-5 text-green-600" aria-hidden="true" />
                              ) : (
                                <XIcon className="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                              )}

                              <span className="sr-only">{tier.value === true ? 'Yes' : 'No'}</span>
                            </>
                          )}
                        </span>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Fake card borders */}
            <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
              <div className="w-1/4 pr-4" />
              <div className="w-1/4 px-4">
                <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
              </div>
              <div className="w-1/4 px-4">
                <div className="w-full h-full rounded-lg ring-2 ring-green-600 ring-opacity-100" />
              </div>
              <div className="w-1/4 pl-4">
                <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
              </div>
            </div>
          </div>

          <h3 className="mt-10 text-sm font-bold text-gray-900">{t("pricing_tech_head")}</h3>

          <div className="mt-6 relative">
            {/* Fake card backgrounds */}
            <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
              <div className="w-1/4 pr-4" />
              <div className="w-1/4 px-4">
                <div className="w-full h-full bg-white rounded-lg shadow" />
              </div>
              <div className="w-1/4 px-4">
                <div className="w-full h-full bg-white rounded-lg shadow-md" />
              </div>
              <div className="w-1/4 pl-4">
                <div className="w-full h-full bg-white rounded-lg shadow" />
              </div>
            </div>

            <table className="relative w-full">
              <caption className="sr-only">Perk comparison</caption>
              <thead>
                <tr className="text-left">
                  <th scope="col">
                    <span className="sr-only">Perk</span>
                  </th>
                  {plans.map((plan) => (
                    <th key={plan.title} scope="col">
                      <span className="sr-only">{plan.title} plan</span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100">
                {technical.map((perk) => (
                  <tr key={perk.title}>
                    <th scope="row" className="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                      {perk.title}
                    </th>
                    {perk.tiers.map((tier) => (
                      <td
                        key={tier.title}
                        className={classNames(
                          index === perk.tiers.length - 1 ? 'pl-4' : 'px-4',
                          'relative w-1/4 py-0 text-center'
                        )}
                      >
                        <span className="relative w-full h-full py-3">
                          {tier.value === true ? (
                            <CheckIcon className="mx-auto h-5 w-5 text-green-600" aria-hidden="true" />
                          ) : (
                            <XIcon className="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                          )}

                          <span className="sr-only">{tier.value === true ? 'Yes' : 'No'}</span>
                        </span>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Fake card borders */}
            <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
              <div className="w-1/4 pr-4" />
              <div className="w-1/4 px-4">
                <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
              </div>
              <div className="w-1/4 px-4">
                <div className="w-full h-full rounded-lg ring-2 ring-green-600 ring-opacity-100" />
              </div>
              <div className="w-1/4 pl-4">
                <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
              </div>
            </div>
          </div>

          <h3 className="mt-10 text-sm font-bold text-gray-900">{t("pricing_price_head")}</h3>

          <div className="mt-6 relative">
            {/* Fake card backgrounds */}
            <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
              <div className="w-1/4 pr-4" />
              <div className="w-1/4 px-4">
                <div className="w-full h-full bg-white rounded-lg shadow" />
              </div>
              <div className="w-1/4 px-4">
                <div className="w-full h-full bg-white rounded-lg shadow-md" />
              </div>
              <div className="w-1/4 pl-4">
                <div className="w-full h-full bg-white rounded-lg shadow" />
              </div>
            </div>

            <table className="relative w-full">
              <caption className="sr-only">Perk comparison</caption>
              <thead>
                <tr className="text-left">
                  <th scope="col">
                    <span className="sr-only">Perk</span>
                  </th>
                  {plans.map((plan) => (
                    <th key={plan.title} scope="col">
                      <span className="sr-only">{plan.title} plan</span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100">
                {pricing.map((perk) => (
                  <tr key={perk.title}>
                    <th scope="row" className="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                      {perk.title}
                    </th>
                    {perk.tiers.map((tier) => (
                      <td
                        key={tier.title}
                        className={classNames(
                          index === perk.tiers.length - 1 ? 'pl-4' : 'px-4',
                          'relative w-1/4 py-0 text-center'
                        )}
                      >
                        <span className="relative w-full h-full py-3">
                        {typeof tier.value === 'string' ? (
                            <span
                              className={classNames(
                                tier.featured ? 'text-green-600' : 'text-gray-900',
                                'text-sm font-medium'
                              )}
                            >
                              {tier.value}
                            </span>
                          ) : (
                            <>
                              {tier.value === true ? (
                                <CheckIcon className="mx-auto h-5 w-5 text-green-600" aria-hidden="true" />
                              ) : (
                                <XIcon className="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                              )}

                              <span className="sr-only">{tier.value === true ? 'Yes' : 'No'}</span>
                            </>
                          )}
                        </span>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Fake card borders */}
            <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
              <div className="w-1/4 pr-4" />
              <div className="w-1/4 px-4">
                <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
              </div>
              <div className="w-1/4 px-4">
                <div className="w-full h-full rounded-lg ring-2 ring-green-600 ring-opacity-100" />
              </div>
              <div className="w-1/4 pl-4">
                <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
              </div>
            </div>
          </div>

        </div>
      </section>
      <section className="mt-10 pt-8 pb-8 bg-white overflow-hidden md:pt-12 md:pb-16">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative">
            <img
              className="mx-auto h-16"
              src={CHESTlogo_dark}
              alt="Chester Beatty"
            />
            <blockquote className="mt-9">
              <div className="max-w-3xl mx-auto text-center text-xl leading-9 font-light text-gray-900">
                <p>
                  &ldquo;{t("test_chest_head")}&rdquo;
              </p>
              </div>
              <footer className="mt-8">
                <div className="md:flex md:items-center md:justify-center">
                  <div className="md:flex-shrink-0">
                    {/*<img
                      className="mx-auto h-10 w-10 rounded-full"
                      src="https://media-exp1.licdn.com/dms/image/C4D03AQFDGm_ix3qHVg/profile-displayphoto-shrink_800_800/0/1517373055666?e=1625702400&v=beta&t=Kq1kFpCrgIKzV35-Zv_l9X-CTbxAIf4cwnkZyHjBDFY"
                      alt=""
                    />*/}
                  </div>
                  <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div className="text-base font-medium text-gray-900">{t("test_chest_name")}</div>

                    <svg className="hidden md:block mx-1 h-5 w-5 text-gray-600" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>

                    <div className="text-base font-medium text-gray-500">{t("test_chest_post")}</div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>
    </div>

    <div className="bg-gradient-to-r from-green-500 to-teal-600">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block text-gray-900">{t("cta_headline5")}</span>
          <span className="block text-green-100">{t("cta_headline2")}</span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <a
              href="tel:+353539407070"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
            ><PhoneIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            053 940 70 70
            </a>
          </div>
          <div className="ml-3 inline-flex rounded-md shadow">
            <a
              href="mailto:info@snasta.ie"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-gray-100"
            ><MailIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              info@snasta.ie
            </a>
          </div>
        </div>
      </div>
      </div>    
<Footer t={t} />
    </div>
    </div>
  )
}


export default Pricing;