import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import DElogo from '../assets/de-logo-gray-400.svg';
//import DDLETBlogo from '../assets/ddletb-logo-gray-400.svg';
import DLRlogo from '../assets/dlr-logo-gray-400.svg';
import WEXlogo from '../assets/wex-logo-gray-400.svg';
import COURTSlogo from '../assets/courts-logo-gray-400.svg';
//import EIRGRIDlogo from '../assets/eirgrid-logo-gray-400.svg';
import HSElogo from '../assets/hse-logo-gray-400.svg';
import POBALlogo from '../assets/pobal-logo-gray-400.svg';
//import CSOlogo from '../assets/cso-logo-gray-400.svg';
import CORUlogo from '../assets/coru-logo-gray-400.svg';
import BARNlogo from '../assets/barn-logo-gray-400.svg';
import CHESTlogo from '../assets/chest-logo-gray-400.svg';
import DEDUlogo from '../assets/dedu-logo-gray-400.svg';
import ESBlogo from '../assets/esb-logo-gray-400.svg';
import NLIlogo from '../assets/nli-logo-gray-400.svg';
import NLIlogo_dark from '../assets/nli-logo-gray-500.svg';
import { useTranslation } from "react-i18next";
import LanguageSwitcher from '../components/LanguageSwitcher';

import { AnnotationIcon, BadgeCheckIcon, PhoneIcon, LightningBoltIcon, LockClosedIcon, MailIcon, ClockIcon, DesktopComputerIcon, DatabaseIcon, ChevronDoubleRightIcon } from '@heroicons/react/outline'

function Home() {
  const { t, i18n } = useTranslation();
  const features = [
    {
      name: t("features_name_1"),
      description: t("features_desc_1"),
      icon: BadgeCheckIcon,
    },
    {
      name: t("features_name_2"),
      description: t("features_desc_2"),
      icon: LockClosedIcon,
    },
    {
      name: t("features_name_3"),
      description: t("features_desc_3"),
      icon: DatabaseIcon,
    },
    {
      name: t("features_name_4"),
      description: t("features_desc_4"),
      icon: ClockIcon,
    },
  ]
  return (
    <>
      <div className="bg-gradient-to-r from-green-500 to-teal-600">
      <Header 
        pageMeta={{
          title: "Home",
          keywords: ["translation", "irish", "gaelic", "gaeilge", "language", "official languages act", "snasta", "irish translation", "service", "english"],
          description: "Snasta are a leading provider of professional, Foras na Gaeilge accredited, Irish translation services to the public and private sectors.",
          canonical: "https://www.snasta.ie/"
        }} t={t}
    />
        <main className="mt-12 pb-12 mx-auto max-w-7xl px-4">
          <div className="text-center">
            <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
              <span className="block lg:inline-block">{t("headline1")}</span>{' '}<span className="block text-green-100 lg:inline-block">{t("headline2")}</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-green-100 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              {t("subline")}
            </p>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <Link
                  to="/Translation"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 md:py-4 md:text-lg md:px-10"
                ><ChevronDoubleRightIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                  {t("trans_services")}
                </Link>
              </div>
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                <a
                  href="mailto:info@snasta.ie"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-gray-100 md:py-4 md:text-lg md:px-10"
                ><MailIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                  {t("emailus")}
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-10 lg:px-8">
        <p className="text-center text-base font-semibold uppercase text-gray-600 tracking-wider">
        {t("subline2")}
        </p>
        <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
          <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img
              className="max-h-12"
              src={POBALlogo}
              alt="Pobal"
            />
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img className="max-h-12" src={ESBlogo} alt="ESB Networks" />
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img className="max-h-12" src={DLRlogo} alt="Dún Laoghaire-Rathdown County Council" />
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img className="max-h-12" src={WEXlogo} alt="Wexford County Council" />
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img
              className="max-h-12"
              src={NLIlogo}
              alt="NLI"
            />
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img
              className="max-h-12"
              src={HSElogo}
              alt="HSE"
            />
          </div>
          
        </div>
      </div>
    </div>
      <div className="py-6 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            {t("subline3")}
          </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            {t("subline3_desc")}
          </p>
          </div>

          <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              {features.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-full bg-gradient-to-r from-green-500 to-teal-600 text-white">
                      <feature.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>


      <section className="mt-10 pt-8 pb-8 bg-gray-100 overflow-hidden md:pt-12 md:pb-16">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative">
            <img
              className="mx-auto h-16"
              src={NLIlogo_dark}
              alt="NLI"
            />
            <blockquote className="mt-9">
              <div className="max-w-3xl mx-auto text-center text-xl leading-9 font-light text-gray-900">
                <p>
                  &ldquo;{t("test_nli_head")}&rdquo;
              </p>
              </div>
              <footer className="mt-8">
                <div className="md:flex md:items-center md:justify-center">
                  <div className="md:flex-shrink-0">
                    {/*<img
                      className="mx-auto h-10 w-10 rounded-full"
                      src="https://media-exp1.licdn.com/dms/image/C4D03AQFDGm_ix3qHVg/profile-displayphoto-shrink_800_800/0/1517373055666?e=1625702400&v=beta&t=Kq1kFpCrgIKzV35-Zv_l9X-CTbxAIf4cwnkZyHjBDFY"
                      alt=""
                    />*/}
                  </div>
                  <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div className="text-base font-medium text-gray-900">{t("test_nli_name")}</div>

                    <svg className="hidden md:block mx-1 h-5 w-5 text-gray-600" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>

                    <div className="text-base font-medium text-gray-500">{t("test_nli_post")}</div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>

      <div className="bg-gradient-to-r from-green-500 to-teal-600">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block text-gray-900">{t("cta_headline1")}</span>
            <span className="block text-green-100">{t("cta_headline2")}</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <a
                href="tel:+353539407070"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              ><PhoneIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            053 940 70 70
            </a>
            </div>
            <div className="ml-3 inline-flex rounded-md shadow">
              <a
                href="mailto:info@snasta.ie"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-gray-100"
              ><MailIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              info@snasta.ie
            </a>
            </div>
          </div>
        </div>
      </div>
      <Footer t={t} i18n={i18n} />
    </>
  )
}

export default Home;