import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import NUIGSUlogo from '../assets/nuigsu-logo-gray-500.svg';
import { PhoneIcon, MailIcon } from '@heroicons/react/outline'
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

function AboutUs() {
    const { t } = useTranslation();
    const people = [
      {
        name: 'James Pelow',
        role: t("aboutus_title1"),
        imageUrl:
          '/assets/james.jpg',
        bio:
        t("aboutus_bio1"),
        linkedinUrl: 'https://www.linkedin.com/in/pelowj/',
        didNo: '053 940 7071',
        didUrl: 'tel:+353539407071',
        isFnaG: 1,
        isMITIA: 1,
        isLegal: 1,
        isMemsourceT: 1,
        isBA: 1,
        isHDip: 1,
      },
      {
          name: 'Alba Pelow',
          role: t("aboutus_title2"),
          imageUrl:
            '/assets/alba.jpg',
          bio:
          t("aboutus_bio2"),
          linkedinUrl: 'https://www.linkedin.com/in/alba-molinero-vigara-005a6166/',
          didNo: '053 940 7072',
          didUrl: 'tel:+353539407072',
          isBA: 1,
          isMemsourceU: 1,
        },
      ]
  
      const translators = [
        {
          name: 'Darren Mag Aoidh',
          role: t("aboutus_trans"),
          imageUrl:
            '/assets/no-img.png',
            isFnaG: 1,
            isBA: 1,
            isPGDip: 1,
        },{
          name: 'Ruaidhrí Breathnach',
          role: t("aboutus_trans"),
          imageUrl:
            '/assets/ruaidhri.jpg',
            isBA: 1,
            isPGDip: 1,
        },
        {
              name: 'Gearóid Ó Fathaigh',
              role: t("aboutus_trans"),
              imageUrl:
                '/assets/gearoid.jpg',
                isFnaG: 1,
                isHDip: 1,
                isBA: 1,
            },
            {
              name: 'Edel de Paor',
              role: t("aboutus_trans"),
              imageUrl:
                '/assets/no-img.png',
                isFnaG: 1,
                isBA: 1,
            },
            {
              name: 'Chris McDowell',
              role: t("aboutus_trans"),
              imageUrl:
                '/assets/no-img.png',
                isFnaG: 1,
                isMITIA: 1,
                isLegal: 1,
          
            },
            {
              name: 'Dónal de Barra',
              role: t("aboutus_voa"),      
              imageUrl:
                '/assets/donal.jpg',
              isWoVO: 1,
            },
            {
              name: 'Doireann Ní Bhriain',
              role: t("aboutus_voa"),
              imageUrl:
                '/assets/doireann.jpg',
            },
        ]  
    return (
        <div className="AboutUs">
        <div className="bg-gradient-to-r from-green-500 to-teal-600">
        <Header 
        pageMeta={{
          title: "About Us",
          keywords: ["translation", "irish", "gaelic", "gaeilge", "language", "official languages act", "snasta", "irish translation", "service", "english"],
          description: "Snasta are a leading provider of professional, Foras na Gaeilge accredited, Irish translation services to the public and private sectors.",
        }} t={t}
    />
        </div>
     
    <div className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-10">
        <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">{t("aboutus_headline1")}</h2>
            <p className="text-xl text-gray-500">
            {t("aboutus_subline1")}
            </p>
          </div>
          <div className="lg:col-span-2">
            <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
              {people.map((person) => (
                <li key={person.name}>
                  <div className="space-y-4">
                    <div className="aspect-w-3 aspect-h-2">
                      <img className="object-cover shadow-lg rounded-lg" src={person.imageUrl} alt="" loading="lazy" />
                    </div>
                    <div className="text-lg leading-6 font-medium space-y-1">
                      <h3>{person.name}</h3>
                      <p className="text-green-600">{person.role}</p>
                      <a
                      href={person.didUrl}
                      className="inline-flex whitespace-nowrap text-base font-medium items-center justify-center text-base text-gray-500 hover:text-gray-900"
                    ><PhoneIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                      {person.didNo}
                    </a>
                    </div>
                    <div className="text-med">
                      <p className="text-gray-500">{person.bio}</p>
                    </div>
                    <div className="m-0">
                    { person.isFnaG && <span className="inline-block px-2.5 py-0.5 mr-1 mb-1 rounded-full text-sm font-medium bg-gray-200 text-gray-800">Foras na Gaeilge</span> }
                    { person.isBA && <span className="inline-block px-2.5 py-0.5 mr-1 mb-1 rounded-full text-sm font-medium bg-gray-200 text-gray-800">B.A.</span> }
                    { person.isMA && <span className="inline-block px-2.5 py-0.5 mr-1 mb-1 rounded-full text-sm font-medium bg-gray-200 text-gray-800">M.A.</span> }
                    { person.isHDip && <span className="inline-block px-2.5 py-0.5 mr-1 mb-1 rounded-full text-sm font-medium bg-gray-200 text-gray-800">{t("aboutus_hdip")}</span> }
                    { person.isPGDip && <span className="inline-block px-2.5 py-0.5 mr-1 mb-1 rounded-full text-sm font-medium bg-gray-200 text-gray-800">{t("aboutus_pgdip")}</span> }
                    { person.isMITIA && <span className="inline-block items-center px-2.5 py-0.5 mr-1 mb-1 rounded-full text-sm font-medium bg-gray-200 text-gray-800">M.A.T.I.I.</span> }
                    { person.isLegal && <span className="inline-block items-center px-2.5 py-0.5 mr-1  b-1 rounded-full text-sm font-medium bg-gray-200 text-gray-800">{t("aboutus_cert")}</span> }
                    { person.isMemsourceT && <span className="inline-block items-center px-2.5 py-0.5 mr-1  b-1 rounded-full text-sm font-medium bg-gray-200 text-gray-800">{t("aboutus_phrase_cert_train")}</span> }
                    { person.isMemsourceU && <span className="inline-block items-center px-2.5 py-0.5 mr-1  b-1 rounded-full text-sm font-medium bg-gray-200 text-gray-800">{t("aboutus_phrase_cert")}</span> }
</div>
                    <ul className="flex space-x-5">
                    { person.twitterUrl &&
                      <li>
                        <a href={person.twitterUrl} className="text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Twitter</span>
                          <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                          </svg>
                        </a>
                      </li>
                    }
                    { person.linkedinUrl &&
                      <li>
                        <a href={person.linkedinUrl} className="text-gray-400 hover:text-gray-500">
                          <span className="sr-only">LinkedIn</span>
                          <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            <path
                              fillRule="evenodd"
                              d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                      </li>
                    }
                    </ul>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div className="bg-gray-100">
      <div className="mx-auto py-10 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-10">
        <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">{t("aboutus_headline2")}</h2>
            <p className="text-xl text-gray-500">
              {t("aboutus_subline2")}
            </p>
          </div>
          <div className="lg:col-span-2">
            <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
              {translators.map((person) => (
                <li key={person.name}>
                  <div className="space-y-4">
                    <div className="aspect-w-3 aspect-h-2">
                      <img className="object-cover filter grayscale shadow-lg rounded-lg" src={person.imageUrl} alt="" loading="lazy" />
                    </div>
                    <div className="text-lg leading-6 font-medium space-y-1">
                      <h3>{person.name}</h3>
                      <p className="text-green-600">{person.role}</p>
                    </div>
                    <div className="text-med">
                      <p className="text-gray-500">{person.bio}</p>
                    </div>
                    <div className="m-0">
                    { person.isFnaG && <span className="inline-block px-2.5 py-0.5 mr-1 mb-1 rounded-full text-sm font-sm bg-gray-400 text-gray-50">Foras na Gaeilge</span> }
                    { person.isLegal && <span className="inline-block px-2.5 py-0.5 mr-1 mb-1 rounded-full text-sm font-sm bg-gray-400 text-gray-50">{t("aboutus_cert")}</span> }
                    { person.isMITIA && <span className="inline-block px-2.5 py-0.5 mr-1 mb-1 rounded-full text-sm font-sm bg-gray-400 text-gray-50">M.A.T.I.I.</span> }
                    { person.isBA && <span className="inline-block px-2.5 py-0.5 mr-1 mb-1 rounded-full text-sm font-medium bg-gray-400 text-gray-50">B.A.</span> }
                    { person.isMA && <span className="inline-block px-2.5 py-0.5 mr-1 mb-1 rounded-full text-sm font-medium bg-gray-400 text-gray-50">M.A.</span> }
                    { person.isHDip && <span className="inline-block px-2.5 py-0.5 mr-1 mb-1 rounded-full text-sm font-medium bg-gray-400 text-gray-50">{t("aboutus_hdip")}</span> }
                    { person.isPGDip && <span className="inline-block px-2.5 py-0.5 mr-1 mb-1 rounded-full text-sm font-medium bg-gray-400 text-gray-50">{t("aboutus_pgdip")}</span> }
                    { person.isWoVO && <span className="inline-block px-2.5 py-0.5 mr-1 mb-1 rounded-full text-sm font-medium bg-gray-400 text-gray-50">{t("aboutus_wovo")}</span> }
                    </div>
                    <ul className="flex space-x-5">
                    { person.twitterUrl &&
                      <li>
                        <a href={person.twitterUrl} className="text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Twitter</span>
                          <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                          </svg>
                        </a>
                      </li>
                    }
                    { person.linkedinUrl &&
                      <li>
                        <a href={person.linkedinUrl} className="text-gray-400 hover:text-gray-500">
                          <span className="sr-only">LinkedIn</span>
                          <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            <path
                              fillRule="evenodd"
                              d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                      </li>
                    }
                    </ul>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      </div>

      <section className="mt-10 pt-8 pb-8 bg-white overflow-hidden md:pt-4 md:pb-16">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative">
            <img
              className="mx-auto h-16"
              src={NUIGSUlogo}
              alt="Workcation"
              loading="lazy"
            />
            <blockquote className="mt-9">
              <div className="max-w-3xl mx-auto text-center text-xl leading-9 font-light text-gray-900">
                <p>
                  &ldquo;{t("test_nuigsu_head")}&rdquo;
              </p>
              </div>
              <footer className="mt-8">
                <div className="md:flex md:items-center md:justify-center">
                  <div className="md:flex-shrink-0">
                    {/*<img
                      className="mx-auto h-10 w-10 rounded-full"
                      src="https://media-exp1.licdn.com/dms/image/C4D03AQFDGm_ix3qHVg/profile-displayphoto-shrink_800_800/0/1517373055666?e=1625702400&v=beta&t=Kq1kFpCrgIKzV35-Zv_l9X-CTbxAIf4cwnkZyHjBDFY"
                      alt=""
                    />*/}
                  </div>
                  <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div className="text-base font-medium text-gray-900">{t("test_nuigsu_name")}</div>

                    <svg className="hidden md:block mx-1 h-5 w-5 text-gray-600" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>

                    <div className="text-base font-medium text-gray-500">{t("test_nuigsu_post")}</div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>

      <div className="bg-gradient-to-r from-green-500 to-teal-600">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block text-gray-900">{t("cta_headline3")}</span>
          <span className="block text-green-100">{t("cta_headline2")}</span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <a
              href="tel:+353539407070"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
            ><PhoneIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            053 940 70 70
            </a>
          </div>
          <div className="ml-3 inline-flex rounded-md shadow">
            <a
              href="mailto:info@snasta.ie"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-gray-100"
            ><MailIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              info@snasta.ie
            </a>
          </div>
        </div>
      </div>
      </div>

    <Footer t={t} />
    </div>
);
}

export default AboutUs;